.messageFormWrapper {
    display: flex;
    flex-direction: column;

    .formInput {
        width: 100%;
        margin-bottom: 1rem;
        border: none;
        border-bottom: 1px solid black;
        resize: none;
        height: 70px;
        outline: none;
        background: #edebeb;
        box-sizing: border-box;
    }
    
    .submitButton {
        margin-left: auto;
        background: black;
        color: white;
        width: 90px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3px 0;
    }
}

