#page {
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    font-size: 1.2rem;

    #pageBody {
        display: flex;
        width: 100%;
    }

    #existingRequests {
        flex: 1;
    }
    #createUpdateForm {
        flex: 1;
    }
}