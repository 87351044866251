.submissionRequestForm {
    text-align: left;
    form {
        display: flex;
        flex-direction: column;

        .formItem {
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;

            input[type="url"]:invalid:not(:focus) {
                color: red;
            }
        }
    }
}
