.userListWrapper {
    display: flex;
    flex-direction: column;
    font-size: 2rem;
    align-items: flex-start;

    .userItem {
        cursor: pointer;

        &.selected {
            text-decoration: underline;
        }
    }
}