.submissionRequestItem {
    margin: 0 0 30px;
    padding: 10px 0 0;
    border-top: 1px solid black;
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;

    &.isPast {
        opacity: 0.65 !important;
    }

    .editButton {
        margin-left: auto;
        cursor: pointer;
    }
}

table {
    width: 100%;
    text-align: left;
    th {
        width: 100px;
    }
}