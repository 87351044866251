#page {
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
}

#logo {
    width: 188px;
    margin-bottom: 4.3rem;
}

#signupForm {
    width: 90vw;
    height: 90vh;
    max-height: 600px;
    max-width: 340px;
    margin: auto;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    text-align: left;

    input {
        font-size: 2rem;
    }

    .header {
        margin-bottom: 2rem;

        p:nth-last-of-type(1) {
            text-align: right;
        }
    }
    
    .formLabel {
        display: flex;
        flex-direction: column;

        &.invalid {
            color: red;
        }
    
        .phoneFormat {
            font-size: 83%;
        }
    }

    #messagingTerms {
        font-size: 70%;
        color: black;
    }
}

.thankYouText {
    font-size: 2rem;
    margin: auto;
}

.ticker {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
}