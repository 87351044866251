#page {
    height: 100vh;
    width: 100vw;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    flex-direction: row;
    max-width: 1260px;
    margin: auto;
}

.userListContainer {
    width: 28%;
    height: 100%;
    overflow-y: scroll;
}

.messagingContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 72%;
    overflow-wrap: anywhere;

    .messageFeedContainer {
        height: calc(100% - 110px);
        overflow: scroll;
        display: flex;
        flex-direction: column-reverse;
    }

    > div:nth-child(2) {
        height: 100px;
        margin-top: 10px;
    }
}