.messageFeedWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    font-size: 2rem;

    .messageFeedItem {
        margin-bottom: 1.5rem;
    }

    .messageDate {
        font-size: 1.2rem;
    }
}